// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import parse from "html-react-parser";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// ** Icons Imports
import { faDownload, faInfo, faTimes, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ** Store Imports
import {
    deleteTask,
    downloadSourceFile,
    downloadTranslatedFile,
    getBudgetPDF,
    getTask,
    payDraftTask,
    removeTranslatedFile,
    sendBudget,
    sendTaskToProductionDeferred,
    updateAmountToPay,
    updateDaysToAdd,
    updateSourceFilesValidated,
    updateTaskStatusAsCancelled,
    updateTaskStatusAsDraft,
    uploadTranslatedFile
} from "../../store/reducers/task/taskActions";
import { getTranslationTypes } from "../../store/reducers/translationType/translationTypeActions";
import { deleteOtherConcept } from "../../store/reducers/otherConcept/otherConceptActions";
import { getPaymentScheme } from "../../store/reducers/user/userActions";
import * as userTypes from "../../store/reducers/auth/userTypes";

// ** Custom Components
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Datatable from "../../components/Datatable/Datatable";
import OnlyReadInput from "../../components/Form/OnlyReadInput";
import TaskStateTag from "../../components/Task/TaskStateTag";

// ** Actions Import
import * as actions from "../../store/Actions";

// ** Task States
import * as TaskState from "../../utils/enums/TaskState";

// ** Hooks
import { useDate } from "../../hooks/useDate";
import { usePrice } from "../../hooks/usePrice";
import { useRound } from "../../hooks/useRound";

const ShowTask = (props) => {
    const taskId = props.match.params.id;

    // ** States
    const [targetLanguages, setTargetLanguages] = useState("");
    const [sourceFiles, setSourceFiles] = useState([]);
    const [amount, setAmount] = useState("");
    const [days, setDays] = useState("");
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [subtotal, setSubtotal] = useState("");
    const [taxes, setTaxes] = useState("");
    const [total, setTotal] = useState("");
    const [balance, setBalance] = useState("");

    // ** Hooks
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const task = useSelector(state => state.task.task);
    const hasBeenSentToProduction = useSelector(state => state.task.hasBeenSentToProduction);
    const hasBudgetBeenSent = useSelector(state => state.task.hasBudgetBeenSent);
    const hasBudgetBeenDownloaded = useSelector(state => state.task.hasBudgetBeenDownloaded);
    const hasBeenCancelled = useSelector(state => state.task.hasBeenCancelled);
    const hasBeenReactivated = useSelector(state => state.task.hasBeenReactivated);
    const hasBeenDeleted = useSelector(state => state.task.hasBeenDeleted);
    const hasBeenUploadFiles = useSelector(state => state.task.hasBeenUploadFiles);
    const hasBeenDeleteFiles = useSelector(state => state.task.hasBeenDeleteFiles);
    const paymentScheme = useSelector(state => state.user.payment_scheme);
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");
    const success = useSelector(state => state.task.success);
    const paymentForm = useSelector(state => state.task.paymentForm);
    const translationTypes = useSelector(state => state.translationType.translationTypes);
    const successOtherConcept = useSelector(state => state.otherConcept.success);
    const successPayTaskHistory = useSelector(state => state.payTaskHistory.success);
    const locale = useSelector(state => state.locale.locale);
    const { dateTimeFormat, addDaysToDateFormat, latestDueDate } = useDate();
    const price = usePrice();
    const round = useRound();

    const paymentHtmlForm = () => {
        if (paymentForm !== null && paymentForm !== false) {
            return parse(paymentForm);
        }
    }

    useEffect(() => {
        dispatch({type: actions.GET_TASK_SUCESS, payload: null});
        dispatch(getTask(taskId));
        dispatch(getPaymentScheme());
        dispatch(getTranslationTypes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch({type: actions.HIDE_MODAL});
        if (paymentForm !== null) {
            if (paymentForm === false) dispatch(getTask(taskId));
            else {
                let element = document.getElementById("payment_form");
                if (element !== null) element.submit();
                dispatch({type: actions.SET_PAYMENT_FORM, payload: null});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentForm])

    useEffect(() => {
        if (hasBeenSentToProduction) {
            dispatch({type: actions.HIDE_MODAL})
            toast.success(intl.formatMessage({id: "general.sent_to_production"}))
            dispatch({type: actions.RESET_SEND_TASK_TO_PRODUCTION})
            history.push("/tasks");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasBeenSentToProduction])

    useEffect(() => {
        if (hasBeenCancelled) {
            dispatch({type: actions.HIDE_MODAL})
            toast.success(intl.formatMessage({id: "task.cancelled_task"}))
            dispatch({type: actions.RESET_CANCELLED_TASK_STATUS})
            history.push("/tasks");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasBeenCancelled])

    useEffect(() => {
        if (hasBudgetBeenDownloaded) {
            dispatch({type: actions.HIDE_MODAL})
            toast.success(intl.formatMessage({id: "task.budget_donwloaded"}))
            dispatch({type: actions.RESET_DOWNLOADED_BUDGET})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasBudgetBeenDownloaded])

    useEffect(() => {
        if (hasBeenDeleted) {
            dispatch({type: actions.HIDE_MODAL})
            toast.success(intl.formatMessage({id: "task.deleted_task"}))
            dispatch({type: actions.RESET_DELETED_TASK_STATUS})
            history.push("/tasks");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasBeenDeleted])

    useEffect(() => {
        if (hasBeenUploadFiles) {
            dispatch({type: actions.HIDE_MODAL})
            toast.success(intl.formatMessage({id: "task.added_translation_file"}))
            dispatch({type: actions.RESET_UPLOAD_TRANSLATED_FILE_SUCCESS});
            dispatch(getTask(taskId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasBeenUploadFiles])

    useEffect(() => {
        if (hasBeenDeleteFiles) {
            dispatch({type: actions.HIDE_MODAL})
            toast.success(intl.formatMessage({id: "task.deleted_translation_file"}))
            dispatch({type: actions.RESET_REMOVE_TRANSLATED_FILE_SUCCESS});
            dispatch(getTask(taskId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasBeenDeleteFiles])

    useEffect(() => {
        if (hasBeenReactivated) {
            dispatch({type: actions.HIDE_MODAL})
            toast.success(intl.formatMessage({id: "task.reactivated_task"}))
            dispatch({type: actions.RESET_REACTIVATED_TASK_STATUS})
            history.push("/tasks");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasBeenReactivated])

    useEffect(() => {
        if (hasBudgetBeenSent) {
            dispatch({type: actions.HIDE_MODAL})
            toast.success(intl.formatMessage({id: "task.budget_sent"}))
            dispatch({type: actions.RESET_BUDGET_STATUS})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasBudgetBeenSent])

    useEffect(() => {
        if ((success?.type ?? "") === actions.DEL_TASK_SUCESS) {
            dispatch({type: actions.TOGGLE_MODAL});
            dispatch({type: actions.CLEAR_TASK_SUCESS});
            history.push({pathname: "/tasks", exact: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success]);

    useEffect(() => {
        const files = [];
        if (task) {
            task.source_files.forEach(file => files.push({ ...file, source_language: task.source_language }));
            if (task.target_languages.length > 0) {
                setTargetLanguages(task.target_languages
                    .map(lang => intl.formatMessage({id: `language.${lang.target_language.iso_code}`}))
                    .reduce((prev, current) => `${prev}, ${current}`)
                );
            }
            setAmount(task.amount_to_pay > 0 ? task.amount_to_pay.toString() : "");
            setDays(task.days_to_add !== 0 ? task.days_to_add.toString() : "");
            setValidated(task.source_files_validated);

            const total = round((task.taxed_price ?? 0) + (task.other_prices ?? 0) + (task.other_prices_taxes ?? 0), 2);
            setSubtotal(round((task.base_price ?? 0) + (task.other_prices ?? 0), 2));
            setTaxes(round((task.price_taxes ?? 0) + (task.other_prices_taxes ?? 0), 2));
            setTotal(total);

            const payments = round(task.pay_task_histories.reduce((previous, current) => previous + current.amount, 0), 2);
            setBalance(round(total - (payments > 0 ? payments : total), 2));
        }
        setSourceFiles(files);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task]);

    useEffect(() => {
        if (successOtherConcept) {
            dispatch(getTask(taskId));
            dispatch({ type: actions.CREATE_OTHER_CONCEPT, payload: false });
            dispatch({ type: actions.HIDE_MODAL });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successOtherConcept]);

    useEffect(() => {
        if (successPayTaskHistory) {
            dispatch(getTask(taskId));
            dispatch({ type: actions.CREATE_PAY_TASK_HISTORY, payload: false });
            dispatch({ type: actions.HIDE_MODAL });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successPayTaskHistory]);

    const columns = [
        {
            name: intl.formatMessage({id: "task.fileName"}),
            selector: "file_name",
            sortable: true,
            wrap: true
        },
        {
            name: intl.formatMessage({id: "task.language"}),
            selector: row => intl.formatMessage({id: `language.${row.source_language.iso_code}`}),
            sortable: true
        },
        {
            name: ""
        },
        {
            name: "",
            selector: row => (
                <button
                    className="button downloadFile"
                    onClick={() => {
                        dispatch(downloadSourceFile(taskId, row.id, row.file_name.trim()))
                    }}
                    title={intl.formatMessage({id: "general.download"})}
                >
                    <FontAwesomeIcon icon={faDownload}/>
                </button>
            ),
            right: true
        },
        {
            name: intl.formatMessage({id: "task.translated"}),
            selector: row => `(${row.translated_files.length}/${row.word_count.length})`
        }
    ];

    const columns2 = [
        {
            name: intl.formatMessage({id: "task.concept"}),
            selector: "concept",
            sortable: true,
            grow: 2
        },
        {
            name: intl.formatMessage({id: "task.description"}),
            selector: "description",
            sortable: true,
            grow: 2
        },
        {
            name: intl.formatMessage({id: "task.unit"}),
            selector: "unit",
            sortable: true
        },
        {
            name: intl.formatMessage({id: "task.quantity"}),
            selector: row => price(row.quantity, locale.name),
            sortable: true,
            right: true
        },
        {
            name: intl.formatMessage({id: "task.price"}),
            selector: row => `${price(row.price, locale.name)} €`,
            sortable: true,
            right: true
        },
        {
            name: intl.formatMessage({id: "invoice.amount"}),
            selector: row => `${price(row.quantity * row.price, locale.name, 2)} €`,
            sortable: true,
            right: true
        },
        ...(userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM ? [
            {
                name: "",
                maxWidth: "100px",
                selector: row => (
                    <button
                        className="button is-small is-danger"
                        onClick={() => {
                            dispatch({
                                type: "TOGGLE_MODAL",
                                payload: {
                                    title: intl.formatMessage({id: "message_title.delete"}),
                                    message: intl.formatMessage({id: "message.delete"}),
                                    acceptButton: intl.formatMessage({id: "general.delete"}),
                                    cancelButton: intl.formatMessage({id: "general.cancel"}),
                                    acceptButtonClass: "is-danger",
                                    acceptButtonMethod: () => {
                                        dispatch(deleteOtherConcept(row.id));
                                    }
                                }
                            });
                        }}
                        title={intl.formatMessage({ id: "general.delete" })}
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                )
            }
        ] : [])
    ];

    const columns3 = [
        {
            name: intl.formatMessage({id: "general.date"}),
            selector: row => dateTimeFormat(row.created_at, locale.name)
        },
        {
            name: intl.formatMessage({id: "task.payment_method"}),
            selector: row => row.type === "Bonus" ? intl.formatMessage({id: "task.Bonus"}) : row.type,
        },
        {
            name: intl.formatMessage({id: "invoice.amount"}),
            selector: row => `${price(row.amount, locale.name)} €`,
            right: true
        }
    ];

    const onRowClicked = row => {
        dispatch({
            type: actions.SHOW_MODAL,
            payload: {
                modalType: "other_concept",
                title: intl.formatMessage({ id: "task.edit_other_concept" }),
                extraData: { id: row.id, other_concept: row, task_id: taskId }
            }
        });
    }

    const TargetFile = ({ data, wordCount }) => {
        const [file, setFile] = useState(null);

        useEffect(() => setFile(
            data.translated_files.find(file => file.language_id === wordCount.targetLanguage) ?? null
            // eslint-disable-next-line react-hooks/exhaustive-deps
        ), []);

        return (
            <div className="columns is-mobile mb-0">
                <div className="column is-offset-one-fifth is-2-tablet is-one-fifth-desktop is-two-fifths-mobile is-size-7">
                    {intl.formatMessage({id: `language.${wordCount.target_language_o_b_j.iso_code}`})}
                </div>
                <div className="column is-2-tablet is-one-fifth-desktop is-two-fifths-mobile is-size-7">
                    {file && `${intl.formatMessage({id: "task.delivered_on"})} ${dateTimeFormat(file.created_at, locale.name)}`}
                </div>
                <div className="column is-flex is-justify-content-end is-one-fifth-desktop is-3-tablet">
                    {file ? (
                        <>
                            {(userType === userTypes.IDISC_PM || userType === userTypes.IDISC_ADMIN) && (
                                <button
                                    className="button"
                                    onClick={() => {
                                        dispatch({
                                            type: 'TOGGLE_MODAL',
                                            payload: {
                                                title: intl.formatMessage({id: "message_title.delete"}),
                                                message: intl.formatMessage({id: "message.delete"}),
                                                acceptButton: intl.formatMessage({id: "general.delete"}),
                                                cancelButton: intl.formatMessage({id: "general.cancel"}),
                                                acceptButtonClass: "is-danger",
                                                acceptButtonMethod: () => handlerDelete(file.id)
                                            }
                                        });
                                    }}
                                    title={intl.formatMessage({id: "general.delete"})}
                                >
                                    <FontAwesomeIcon icon={faTrash}/>
                                </button>
                            )}
                        </>
                    ) : (
                        <>
                            {
                                (userType === userTypes.IDISC_PM || userType === userTypes.IDISC_ADMIN) &&
                                task.current_task_state.state !== TaskState.DRAFT &&
                                task.current_task_state.state !== TaskState.TEMPORAL &&
                                (
                                    <div className="file">
                                        <label className="file-label">
                                            <input
                                                className="file-input"
                                                onChange={event => onSubmitTranslatedFiles(
                                                    event.target.files,
                                                    data.id,
                                                    wordCount.targetLanguage
                                                )}
                                                type="file"
                                            />
                                            <span className="file-cta has-background-white">
                                                <FontAwesomeIcon icon={faUpload}/>
                                            </span>
                                        </label>
                                    </div>
                                )
                            }
                        </>
                    )}
                    <button
                        className="button ml-1"
                        disabled={!file}
                        onClick={() => {
                            dispatch(downloadTranslatedFile(taskId, file.id, file.file_name.trim()));
                        }}
                        title={intl.formatMessage({id: "general.download"})}
                    >
                        <FontAwesomeIcon icon={faDownload}/>
                    </button>
                </div>
            </div>
        );
    };

    const Targets = ({data}) => (
        <div className="ml-6 px-4">
            {data.word_count.map(wordCount => (
                <TargetFile data={data} key={wordCount.id} wordCount={wordCount} />
            ))}
        </div>
    );

    const showConfirmation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                title: intl.formatMessage({id: "message_title.delete"}),
                message: intl.formatMessage({id: "message.delete"}),
                acceptButton: intl.formatMessage({id: "general.delete"}),
                cancelButton: intl.formatMessage({id: "general.cancel"}),
                acceptButtonClass: "is-danger",
                acceptButtonMethod: delete_task,
            }
        })
    }

    const delete_task = () => {
        dispatch({type: actions.HIDE_MODAL})
        dispatch(deleteTask(taskId));
    }

    const showConfirmationCancel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                title: intl.formatMessage({id: "task.cancel_project"}),
                message: intl.formatMessage({id: "task.cancel_project_message"}),
                acceptButton: intl.formatMessage({id: "general.accept"}),
                cancelButton: intl.formatMessage({id: "general.cancel"}),
                acceptButtonClass: "is-primary",
                acceptButtonMethod: cancelTask,
            }
        })
    }

    const cancelTask = () => {
        dispatch(updateTaskStatusAsCancelled(taskId));
        dispatch({
            type: actions.SHOW_MODAL,
            payload: {
                modalType: "loading",
            }
        })
    }

    const payTask = () => {
        dispatch(payDraftTask(taskId));
        dispatch({
            type: actions.SHOW_MODAL,
            payload: {
                modalType: "loading",
            }
        })
    }

    const sendTaskToProduction = () => {
        dispatch(sendTaskToProductionDeferred(taskId));
        dispatch({
            type: actions.SHOW_MODAL,
            payload: {
                modalType: "loading",
            }
        })
    }

    const getBudget = deliveryType => {
        if (deliveryType === "BUDGET") dispatch(sendBudget(taskId, { lang: locale.name }));
        else {
            const formData = new FormData();
            formData.append("id", taskId);
            formData.append("lang", locale.name);
            const order = task?.current_task_state?.state && task.current_task_state.state !== TaskState.DRAFT;
            dispatch(getBudgetPDF(formData, false, `iDISCxpress-${taskId}-${order ? "Order" : "Quote"}.pdf`));
        }
    }

    const handleGetBudget = () => {
        dispatch({
            type: actions.SHOW_MODAL,
            payload: {
                modalType: "options",
                title: intl.formatMessage({id: "task.document_request"}),
                message: intl.formatMessage({
                    id: `task.${task?.current_task_state?.state && task.current_task_state.state !== TaskState.DRAFT ?
                        "requested_order_document" : "requested_detailed_quote"}`
                }),
                optionButtons: [
                    {
                        label: intl.formatMessage({id: "general.email"}),
                        buttonMethod: () => getBudget("BUDGET"),
                    },{
                        label: intl.formatMessage({id: "general.download_pdf"}),
                        buttonMethod: () => getBudget("BUDGET_PDF"),
                    },
                ]
            }
        })
    }

    const showConfirmationApproved = deferred => {
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                title: intl.formatMessage({id: "task.order_confirmation"}),
                message: (
                    <>
                        <p>
                            <FormattedMessage id="task.task_number"/> xpress: {task.id}
                            <br/>
                            <FormattedMessage id="task.service_level"/>: {task.translation_type[`name_${locale.name}`]}
                            <br/>
                            <FormattedMessage id="task.source_language"/>
                            : <FormattedMessage id={`language.${task.source_language.iso_code}`}/>
                            <br/>
                            <FormattedMessage id="task.target_languages"/>: {targetLanguages}
                            <br/>
                            {task.comments && (
                                <>
                                    <FormattedMessage id="task.comments"/>: {task.comments}
                                </>
                            )}
                            <br/>
                            <FormattedMessage id="general.due_date"/>: {
                            addDaysToDateFormat(
                                latestDueDate(task.word_count / task.target_languages.length, task.translation_type_id),
                                locale.name,
                                task.days_to_add
                            )} <FormattedMessage id="general.end_of_day"/>
                        </p>
                        <p className="my-3">
                            <FormattedMessage id="invoice.amount"/>: {price(subtotal, locale.name)} €
                            <br/>
                            <FormattedMessage id={`tax.${task.tax_name}`}/>: {price(taxes, locale.name)} €
                            <br/>
                            <FormattedMessage id="task.total"/>: {price(total, locale.name)} €
                        </p>
                        <p>
                            <FormattedMessage id="task.order_confirmation_message_payment"/>
                            <br/>
                            {deferred ? (
                                <FormattedMessage id="task.order_confirmation_message_payment_3"/>
                            ) : task.user.customer.balance > 0 ? (
                                <>
                                    {task.user.customer.balance < total &&
                                        `${price(task.user.customer.balance, locale.name)} € `
                                    }
                                    <FormattedMessage id="task.order_confirmation_message_payment_1"/>
                                    {task.user.customer.balance < total && (
                                        <>
                                            <br/>
                                            {price(total - task.user.customer.balance, locale.name)}{" € "}
                                            <FormattedMessage id="task.order_confirmation_message_payment_2"/>
                                        </>
                                    )}
                                </>
                            ) : (
                                <FormattedMessage id="task.order_confirmation_message_payment_2"/>
                            )}
                        </p>
                    </>
                ),
                acceptButton: intl.formatMessage({id: "general.continue"}),
                cancelButton: intl.formatMessage({id: "general.cancel"}),
                acceptButtonClass: "is-primary",
                acceptButtonMethod: deferred ? sendTaskToProduction : payTask,
            }
        });
    }

    const reactivateTask = () => {
        dispatch(updateTaskStatusAsDraft(taskId));
        dispatch({
            type: actions.SHOW_MODAL,
            payload: {
                modalType: "loading",
            }
        })
    }

    const getTaskState = () => {
        if (task?.current_task_state.state) return (
            <>
                <TaskStateTag task={task} />
                {!!task?.changes && (
                    <span className="tag is-danger ml-2">
                        <FormattedMessage id="task.changes"/>
                    </span>
                )}
            </>
        );
    }

    const handlerDelete = id => {
        dispatch({type:"TOGGLE_MODAL"});
        dispatch({type: actions.SHOW_MODAL, payload: {modalType: "loading"}});
        dispatch(removeTranslatedFile(taskId, id));
    }

    const onSubmitAmount = event => {
        event.preventDefault();
        setLoading(true);
        dispatch(updateAmountToPay(taskId, { amount_to_pay: amount.trim() || 0 })).finally(() => setLoading(false));
    }

    const onSubmitDays = event => {
        event.preventDefault();
        setLoading(true);
        dispatch(updateDaysToAdd(taskId, { days_to_add: days.trim() || 0 })).finally(() => setLoading(false));
    }

    const showConfirmationValidated = validated => {
        if (validated) dispatch({
            type: "TOGGLE_MODAL",
            payload: {
                title: intl.formatMessage({id: "task.validate_source_files"}),
                message: intl.formatMessage({id: "task.validate_source_files_message"}),
                acceptButton: intl.formatMessage({id: "general.accept"}),
                cancelButton: intl.formatMessage({id: "general.cancel"}),
                acceptButtonClass: "is-primary",
                acceptButtonMethod: () => onSubmitValidated(validated),
            }
        })
        else onSubmitValidated(validated);
    }

    const onSubmitValidated = validated => {
        dispatch({type: actions.SHOW_MODAL, payload: {modalType: "loading"}});
        setValidated(validated);
        dispatch(updateSourceFilesValidated(taskId, { source_files_validated: validated }))
            .finally(() => {
                dispatch({type: actions.HIDE_MODAL})
                dispatch(getTask(taskId))
            });
    }

    const onSubmitTranslatedFiles = (files, sourceFileId, languageId) => {
        if (files && files.length > 0) {
            dispatch({type: actions.SHOW_MODAL, payload: {modalType: "loading"}});
            let formData = new FormData();
            formData.append("file", files[0]);
            formData.append("source_file_id", sourceFileId);
            formData.append("language_id", languageId);
            dispatch(uploadTranslatedFile(taskId, formData));
        }
    }

    const onClickInfo = type => {
        switch (type) {
            case "name":
                dispatch({
                    type: actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_project_name",
                        title: intl.formatMessage({id: "general.project_name_info"})
                    }
                });
                break;
            case "departments":
                dispatch({
                    type: actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_departments",
                        title: intl.formatMessage({id: "general.departments_info"})
                    }
                });
                break;
            case "languages":
                dispatch({
                    type: actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_languages",
                        title: intl.formatMessage({id: "general.languages_info"})
                    }
                });
                break;
            case "types":
                dispatch({
                    type: actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_translation_types",
                        title: intl.formatMessage({id: "general.translation_types_info"}),
                        extraData: {translationTypes}
                    }
                });
                break;
            case "files":
                dispatch({
                    type: actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_source_files",
                        title: intl.formatMessage({id: "general.source_files_info"})
                    }
                });
                break;
            case "custom_quote":
                dispatch({
                    type: actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_custom_quote",
                        title: intl.formatMessage({id: "general.important"})
                    }
                });
                break;
            case "delivery_date":
                dispatch({
                    type: actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_delivery_date",
                        title: intl.formatMessage({id: "general.delivery_date_info"})
                    }
                });
                break;
            case "amount":
                dispatch({
                    type: actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_amount",
                        title: intl.formatMessage({id: "general.amount_info"}),
                        extraData: {translationType: task.translation_type[`name_${locale.name}`]}
                    }
                });
                break;
            default:
        }
    }

    return (
        <div className="box is-desktop">
            <div className="columns is-mobile">
                <Breadcrumbs
                    title={task?.description ?? ""}
                    destinationTitle={intl.formatMessage({id: "menu.tasks"})}
                    destination="/tasks"
                />
            </div>
            <div className="is-flex is-justify-content-space-between">
                <button className="button is-outlined is-primary mr-2" onClick={() => history.push("/tasks")}>
                    <FormattedMessage id="general.back"/>
                </button>
                <div className="buttons is-right">
                    {
                        (
                            task?.current_task_state?.state === TaskState.TEMPORAL ||
                            task?.current_task_state?.state === TaskState.APPROVED ||
                            task?.current_task_state?.state === TaskState.IN_COURSE ||
                            task?.current_task_state?.state === TaskState.DONE
                        ) && (
                            <>
                                {userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM ? (
                                    <>
                                        <button
                                            className="button is-outlined is-primary"
                                            onClick={() => getBudget("BUDGET_PDF")}
                                        >
                                            <FormattedMessage id="general.download_pdf"/>
                                        </button>
                                        <button
                                            className="button is-outlined is-primary"
                                            onClick={() => history.push(`/tasks/${taskId}/edit/status`)}
                                        >
                                            <FormattedMessage id="task.edit_status"/>
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {task?.changes ? (
                                            <>
                                                {!!(task?.source_files_validated && balance > 0) && (
                                                    <button
                                                        className="button is-outlined is-primary"
                                                        onClick={paymentScheme === "DEFERRED" ?
                                                            sendTaskToProduction : payTask
                                                        }
                                                    >
                                                        <FormattedMessage id="task.approve_changes"/>
                                                    </button>
                                                )}
                                            </>
                                        ) : balance > 0 && paymentScheme === "ON_DEMAND" && (
                                            <button className="button is-outlined is-primary" onClick={payTask}>
                                                <FormattedMessage id="task.make_payment"/>
                                            </button>
                                        )}
                                        <button className="button is-outlined is-primary" onClick={handleGetBudget}>
                                            <FormattedMessage id="task.view_order"/>
                                        </button>
                                    </>
                                )}
                            </>
                        )
                    }
                    {task?.current_task_state?.state === TaskState.DRAFT && (
                        <>
                            {userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM ? (
                                <button
                                    className="button is-outlined is-primary"
                                    onClick={() => getBudget("BUDGET_PDF")}
                                >
                                    <FormattedMessage id="general.download_pdf"/>
                                </button>
                            ) : (
                                <>
                                    <button
                                        className="button is-outlined is-primary"
                                        onClick={() => history.push(`/tasks/${taskId}/edit`)}
                                    >
                                        <FormattedMessage id="task.edit_project"/>
                                    </button>
                                    <button className="button is-outlined is-primary" onClick={showConfirmationCancel}>
                                        <FormattedMessage id="task.cancel_project"/>
                                    </button>
                                    <button className="button is-outlined is-primary" onClick={handleGetBudget}>
                                        <FormattedMessage id="task.view_detailed_quote"/>
                                    </button>
                                    <button
                                        className="button is-outlined is-primary"
                                        onClick={() => showConfirmationApproved(paymentScheme === "DEFERRED")}
                                    >
                                        <FormattedMessage id="task.approve_project"/>
                                    </button>
                                </>
                            )}
                        </>
                    )}
                    {
                        task?.current_task_state?.state === TaskState.CANCELLED &&
                        (userType === userTypes.CLIENT_ADMIN || userType === userTypes.CLIENT_USER) &&
                        (
                            <>
                                <button className="button is-outlined is-primary" onClick={reactivateTask}>
                                    <FormattedMessage id="general.reactivate"/>
                                </button>
                                <button className="button is-outlined is-primary" onClick={showConfirmation}>
                                    <FormattedMessage id="task.delete_project"/>
                                </button>
                            </>
                        )
                    }
                </div>
            </div>
            <hr/>
            <h1 className="title is-5 has-text-primary">
                {intl.formatMessage({id: "general.general_data"})}
            </h1>
            <div className="columns is-desktop">
                <div className={`column ${userType === userTypes.IDISC_ADMIN ? "iis-3-desktop" : "is-4-desktop"}`}>
                    <OnlyReadInput label="task.id" fieldValue={task?.id ?? ""}/>
                </div>
                <div className={`column ${userType === userTypes.IDISC_ADMIN ? "is-3-desktop" : "is-4-desktop"}`}>
                    <div className="columns">
                        <div className="column">
                            <OnlyReadInput label="task.name" fieldValue={task?.description ?? ""}/>
                        </div>
                        <div className="column is-1 is-3-desktop">
                            <button
                                className="button is-black is-rounded is-small px-3"
                                onClick={() => onClickInfo("name")}
                            >
                                <FontAwesomeIcon icon={faInfo}/>
                            </button>
                        </div>
                    </div>
                </div>
                {userType === userTypes.IDISC_ADMIN && (
                    <>
                        <div className="column is-3-desktop">
                            <OnlyReadInput
                                label="invoice.folio"
                                fieldValue={task?.user?.customer && (
                                    <span className={`tag ${
                                        task?.current_task_state?.state !== TaskState.TEMPORAL &&
                                        task?.current_task_state?.state !== TaskState.CALCULATING &&
                                        task?.current_task_state?.state !== TaskState.DRAFT &&
                                        task?.current_task_state?.state !== TaskState.CANCELLED &&
                                        task?.user?.customer?.payment_scheme === "ON_DEMAND" ?
                                            "is-success" : "is-danger"
                                    }`}>
                                        {
                                            task?.current_task_state?.state !== TaskState.TEMPORAL &&
                                            task?.current_task_state?.state !== TaskState.CALCULATING &&
                                            task?.current_task_state?.state !== TaskState.DRAFT &&
                                            task?.current_task_state?.state !== TaskState.CANCELLED &&
                                            task?.user?.customer?.payment_scheme === "ON_DEMAND" ?
                                                intl.formatMessage({id: "task.paid"}) :
                                                intl.formatMessage({id: "task.pending"})
                                        }
                                    </span>
                                )}
                            />
                        </div>
                        <div className="column is-4-desktop">
                            <form onSubmit={onSubmitAmount}>
                                <div className="field">
                                    <label className="label">
                                        <FormattedMessage id="task.amount_to_pay"/>
                                    </label>
                                    <div className="field has-addons">
                                        <div className="control is-expanded">
                                            <input
                                                className="input"
                                                max={999999.99}
                                                min={0.01}
                                                onChange={event => setAmount(event.target.value)}
                                                step={0.01}
                                                type="number"
                                                value={amount}
                                            />
                                        </div>
                                        <div className="control">
                                            <button
                                                className={`button is-info${loading ? " is-loading" : ""}`}
                                                type="submit"
                                            >
                                                <FormattedMessage id="general.save"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </div>
            <div className="columns is-desktop">
                <div className="column">
                    <OnlyReadInput
                        label="general.company"
                        fieldValue={task?.user?.customer.name ?? intl.formatMessage({id: "general.guest"})}
                    />
                </div>
                <div className="column">
                    <div className="columns">
                        <div className="column">
                            <OnlyReadInput label="task.department" fieldValue={task?.department?.title ?? ""}/>
                        </div>
                        <div className="column is-1 is-3-desktop">
                            <button
                                className="button is-black is-rounded is-small px-3"
                                onClick={() => onClickInfo("departments")}
                            >
                                <FontAwesomeIcon icon={faInfo}/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <OnlyReadInput label="task.state" fieldValue={getTaskState()}/>
                </div>
            </div>
            <div className="columns is-desktop">
                <div className="column">
                    <OnlyReadInput
                        label="general.requested_date"
                        fieldValue={task?.created_at && dateTimeFormat(task.created_at, locale.name)}
                    />
                    {task?.user && (
                        <p>{task.user.name} {task.user.lastname}</p>
                    )}
                </div>
                <div className="column">
                    <OnlyReadInput
                        label="general.last_modification_date"
                        fieldValue={task?.updated_at && dateTimeFormat(task.updated_at, locale.name)}
                    />
                    {task?.updated_user_id && (
                        <>
                            {task?.updated_user ? (
                                <p>{task.updated_user.name} {task.updated_user.lastname}</p>
                            ) : (
                                <p><FormattedMessage id="app.name"/></p>
                            )}
                        </>
                    )}
                </div>
                <div className="column">
                    <OnlyReadInput
                        label="general.approved_date"
                        fieldValue={task?.approved_task_state?.updated_at &&
                            dateTimeFormat(task.approved_task_state.updated_at, locale.name)}
                    />
                    {task?.approved_user && (
                        <p>{task.approved_user.name} {task.approved_user.lastname}</p>
                    )}
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <OnlyReadInput label="task.comments" fieldValue={task?.comments ?? ""}/>
                </div>
            </div>
            <hr/>
            <h1 className="title is-5 has-text-primary">
                {intl.formatMessage({id: "language.languages"})}
            </h1>
            {task?.current_task_state?.state === TaskState.DRAFT && !(userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) && (
                <div className="columns is-desktop">
                    <div className="column">
                        ({intl.formatMessage({id: "task.languages_info"})})
                    </div>
                </div>
            )}
            <div className="columns is-desktop">
                <div className="column">
                    <OnlyReadInput
                        label="task.source_language"
                        fieldValue={task?.source_language &&
                            intl.formatMessage({id: `language.${task.source_language?.iso_code}`})
                        }
                    />
                </div>
                <div className="column">
                    <OnlyReadInput label="task.target_languages" fieldValue={targetLanguages}/>
                </div>
                <div className="column is-1">
                    <button
                        className="button is-black is-rounded is-small px-3"
                        onClick={() => onClickInfo("languages")}
                    >
                        <FontAwesomeIcon icon={faInfo}/>
                    </button>
                </div>
            </div>
            <hr/>
            <h1 className="title is-5 has-text-primary">
                {intl.formatMessage({id: "task.translation_type"})}
            </h1>
            {task?.current_task_state?.state === TaskState.DRAFT && !(userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) && (
                <div className="columns is-desktop">
                    <div className="column">
                        ({intl.formatMessage({id: "task.translation_type_info"})})
                    </div>
                </div>
            )}
            <div className="columns is-desktop">
                <div className="column">
                    <OnlyReadInput
                        label="task.translation_type"
                        fieldValue={task?.translation_type && task?.translation_type[`name_${locale.name}`]}
                    />
                </div>
                <div className="column">
                    <div className="field">
                        <label className="label">
                            <FormattedMessage id="task.translation_type_description"/>
                        </label>
                        <div className="control">
                            {task?.translation_type && (
                                <>
                                    <p className="has-text-weight-bold">
                                        {task.translation_type[`description_${locale.name}`]}
                                    </p>
                                    <p>
                                        {task.translation_type[`description_2_${locale.name}`]}
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="column is-1">
                    <button
                        className="button is-black is-rounded is-small px-3"
                        onClick={() => onClickInfo("types")}
                    >
                        <FontAwesomeIcon icon={faInfo}/>
                    </button>
                </div>
            </div>
            <hr/>
            <div className="columns is-desktop">
                <div className="column">
                    <h2 className="title is-5 has-text-primary">
                        {intl.formatMessage({id: "task.source_files"})}
                    </h2>
                </div>
                <div className="column is-1">
                    <button
                        className="button is-black is-rounded is-small px-3"
                        onClick={() => onClickInfo("files")}
                    >
                        <FontAwesomeIcon icon={faInfo}/>
                    </button>
                </div>
            </div>
            {task?.current_task_state?.state === TaskState.DRAFT && !(userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) && (
                <div className="columns is-desktop">
                    <div className="column">
                        ({intl.formatMessage({id: "task.source_files_info"})})
                    </div>
                </div>
            )}
            {task?.current_task_state?.state !== TaskState.CALCULATING && (
                <div className="columns is-desktop is-info message">
                    <div className="column message-body">
                        <FormattedMessage id="task.source_files_info_2"/>
                    </div>
                </div>
            )}
            <Datatable
                columns={columns}
                data={sourceFiles}
                expandableRows={task?.current_task_state?.state !== TaskState.CALCULATING}
                expandableRowsComponent={<Targets/>}
                expandOnRowClicked
                pointerOnHover={false}
            />
            <div className="columns mt-3">
                <div className="column is-offset-6">
                    <div className="custom-border-black-3 columns has-background-danger-light">
                        <div className="column has-text-right has-text-weight-bold">
                            <FormattedMessage id="task.total_translation"/>
                        </div>
                        <div className="column has-text-right">
                            {task?.current_task_state?.state !== TaskState.CALCULATING ? (
                                `${price(task?.base_price ?? 0, locale.name)} €`
                            ) : (
                                <FormattedMessage id="task.state.CALCULATING"/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {task?.current_task_state?.state !== TaskState.CALCULATING && (
                <div className="has-text-right">
                    (
                    <Link to={`/tasks/${taskId}/quote`}>
                        <FormattedMessage id="task.view_word_count"/>
                    </Link>
                    )
                </div>
            )}
            <div className="columns is-desktop mt-3">
                <div className="column">
                    <p className="has-text-weight-bold is-uppercase">
                        <FormattedMessage id="general.important"/>:
                    </p>
                    {!task?.custom_quote ? (
                        <OnlyReadInput
                            label="task.translating_editable_text"
                            fieldValue={
                                task?.current_task_state?.state === TaskState.DRAFT &&
                                !(userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) &&
                                <FormattedMessage id="task.translating_editable_text_description"/>
                            }
                        />
                    ) : !task?.source_files_validated ? (
                        <OnlyReadInput
                            label="task.source_files_validation_requested"
                            fieldValue={
                                task?.current_task_state?.state === TaskState.DRAFT &&
                                !(userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) &&
                                <FormattedMessage id="task.source_files_validation_requested_description"/>
                            }
                        />
                    ) : (
                        <OnlyReadInput
                            label="task.translating_validated_text"
                            fieldValue={
                                task?.current_task_state?.state === TaskState.DRAFT &&
                                !(userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) &&
                                <FormattedMessage id="task.translating_validated_text_description"/>
                            }
                        />
                    )}
                    {(userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) && (
                        <div className="field mt-1">
                            <div className="control">
                                <label className="checkbox">
                                    <input
                                        className="mr-1"
                                        checked={validated}
                                        onChange={event => showConfirmationValidated(event.target.checked)}
                                        type="checkbox"
                                    />
                                    <FormattedMessage id="task.validate_source_files"/>
                                </label>
                            </div>
                        </div>
                    )}
                </div>
                <div className="column is-1">
                    <button
                        className="button is-black is-rounded is-small px-3"
                        onClick={() => onClickInfo("custom_quote")}
                    >
                        <FontAwesomeIcon icon={faInfo}/>
                    </button>
                </div>
            </div>
            {(task?.other_concepts.length > 0 || userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) && (
                <>
                    <hr/>
                    <h1 className="title is-5 has-text-primary">
                        {intl.formatMessage({id: "task.other_concepts"})}
                    </h1>
                    {(userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) && (
                        <div className="is-flex is-justify-content-end">
                            <button
                                className="button is-outlined is-primary"
                                onClick={() => {
                                    dispatch({
                                        type: actions.SHOW_MODAL,
                                        payload: {
                                            modalType: "other_concept",
                                            title: intl.formatMessage({id: "task.add_other_concept"}),
                                            extraData: {task_id: taskId}
                                        }
                                    });
                                }}
                            >
                                <FormattedMessage id="task.add_other_concept"/>
                            </button>
                        </div>
                    )}
                    <Datatable
                        columns={columns2}
                        data={task?.other_concepts ?? []}
                        onRowClicked={row => userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM ?
                            onRowClicked(row) : null
                        }
                        pointerOnHover={userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM}
                    />
                    <div className="columns mt-3">
                        <div className="column is-offset-6">
                            <div className="custom-border-black-3 columns has-background-danger-light">
                                <div className="column has-text-right has-text-weight-bold">
                                    <FormattedMessage id="task.total_other_concepts"/>
                                </div>
                                <div className="column has-text-right">
                                    {`${price(task?.other_prices ?? 0, locale.name)} €`}
                                </div>
                            </div>
                        </div>
                    </div>
                    {(userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) && (
                        <div className="columns">
                            <div className="column is-offset-9">
                                <form onSubmit={onSubmitDays}>
                                    <div className="field">
                                        <label className="label">
                                            <FormattedMessage id="task.days_to_add"/>
                                        </label>
                                        <div className="field has-addons">
                                            <div className="control is-expanded">
                                                <input
                                                    className="input"
                                                    max={999}
                                                    min={-999}
                                                    onChange={event => setDays(event.target.value)}
                                                    type="number"
                                                    value={days}
                                                />
                                            </div>
                                            <div className="control">
                                                <button
                                                    className={`button is-info${loading ? " is-loading" : ""}`}
                                                    type="submit"
                                                >
                                                    <FormattedMessage id="general.save"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </>
            )}
            <hr/>
            <div className="custom-border-black-3 columns has-background-danger-light is-vcentered">
                <div className="column is-offset-4-desktop">
                    <div className="columns is-mobile">
                        <div className="column has-text-right">
                            <FormattedMessage id="invoice.amount_taxes_not_included"/>
                        </div>
                        <div className="column has-text-centered is-3">
                        </div>
                        <div className="column has-text-right is-3">
                            {task?.current_task_state?.state !== TaskState.CALCULATING ? (
                                `${price(subtotal, locale.name)} €`
                            ) : (
                                <FormattedMessage id="task.state.CALCULATING"/>
                            )}
                        </div>
                    </div>
                    <div className="columns is-mobile">
                        <div className="column has-text-right">
                            {task?.tax_name && (
                                <FormattedMessage id={`tax.${task.tax_name}`}/>
                            )}
                        </div>
                        <div className="column has-text-centered is-3">
                            {`${task?.tax_percentage ?? 0}%`}
                        </div>
                        <div className="column has-text-right is-3">
                            {task?.current_task_state?.state !== TaskState.CALCULATING ? (
                                `${price(taxes, locale.name)} €`
                            ) : (
                                <FormattedMessage id="task.state.CALCULATING"/>
                            )}
                        </div>
                    </div>
                    <div className="columns is-mobile">
                        <div className="column has-text-right">
                            <FormattedMessage id="task.total"/>
                        </div>
                        <div className="column has-text-centered is-3">
                            (EUR)
                        </div>
                        <div className="column has-text-right is-3">
                            {task?.current_task_state?.state !== TaskState.CALCULATING ? (
                                `${price(total, locale.name)} €`
                            ) : (
                                <FormattedMessage id="task.state.CALCULATING"/>
                            )}
                        </div>
                    </div>
                </div>
                <div className="column is-1">
                    <button
                        className="button is-black is-rounded is-small ml-5 px-3"
                        onClick={() => onClickInfo("amount")}
                    >
                        <FontAwesomeIcon icon={faInfo}/>
                    </button>
                </div>
            </div>
            <div className="columns">
                <div className="column is-offset-4">
                    <div className="columns">
                        <div className="column has-text-right has-text-weight-bold">
                            <FormattedMessage id="general.delivery_date"/>
                        </div>
                        <div className="column has-text-right">
                            {task?.current_task_state?.state !== TaskState.CALCULATING ? (
                                <>
                                    {task?.due_date && (
                                        <>
                                            {task?.current_task_state?.state === TaskState.DRAFT ? (
                                                <>
                                                    {`${addDaysToDateFormat(
                                                        latestDueDate(
                                                            task.word_count / task.target_languages.length,
                                                            task.translation_type_id
                                                        ),
                                                        locale.name,
                                                        task.days_to_add
                                                    )} ${intl.formatMessage({id: "general.end_of_day"})}`}
                                                    <div className="is-size-7 mt-1">
                                                        <FormattedMessage id="general.delivery_date_message"/>
                                                    </div>
                                                </>
                                            ) : (
                                                `${addDaysToDateFormat(
                                                    task.due_date,
                                                    locale.name,
                                                    task.days_to_add
                                                )} ${intl.formatMessage({id: "general.end_of_day"})}`
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <FormattedMessage id="task.state.CALCULATING"/>
                            )}
                        </div>
                    </div>
                </div>
                <div className="column is-1">
                    <button
                        className="button is-black is-rounded is-small ml-5 px-3"
                        onClick={() => onClickInfo("delivery_date")}
                    >
                        <FontAwesomeIcon icon={faInfo}/>
                    </button>
                </div>
            </div>
            {(task?.pay_task_histories.length > 0 || userType === userTypes.IDISC_ADMIN) && (
                <>
                    <hr/>
                    <h1 className="title is-5 has-text-primary">
                        {intl.formatMessage({id: "task.payments"})}
                    </h1>
                    {userType === userTypes.IDISC_ADMIN && (
                        <div className="is-flex is-justify-content-end">
                            <button
                                className="button is-outlined is-primary"
                                onClick={() => {
                                    dispatch({
                                        type: actions.SHOW_MODAL,
                                        payload: {
                                            modalType: "payment",
                                            title: intl.formatMessage({id: "task.add_payment"}),
                                            extraData: {task_id: taskId}
                                        }
                                    });
                                }}
                            >
                                <FormattedMessage id="task.add_payment"/>
                            </button>
                        </div>
                    )}
                    <Datatable columns={columns3} data={task?.pay_task_histories} pointerOnHover={false}/>
                    <div className="columns">
                        <div className="column has-text-weight-bold">
                            <FormattedMessage id="task.outstanding_balance"/>
                        </div>
                        <div className="column has-text-right">
                            {`${price(balance, locale.name)} €`}
                        </div>
                    </div>
                </>
            )}
            {paymentHtmlForm()}
        </div>
    );
}

export default connect()(ShowTask);
