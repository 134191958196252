import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from "react-redux";
import * as userTypes from "../../store/reducers/auth/userTypes";

export default function UserDepartments(props) {
    const intl = useIntl();

    const [elements, setElements] = useState([]);
    const [selectedElement, setSelectedElement] = useState("");
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");

    useEffect(() => {
        if (props.elements !== undefined) {

            let elements = []

            for (let i = 0; i < props.elements.length; i++) {
                let isDuplicated = props.selectedElements.find(element => element.id === props.elements[i].id)
                if (isDuplicated === undefined && props.elements[i].type !== userTypes.CLIENT_ADMIN){
                    elements.push(props.elements[i])
                }
            }
            setElements(elements)
        }
    }, [props.elements, props.selectedElements])

    useEffect(() => {
        setSelectedElement("")
    }, [props.selectedElements])

    const disableAddButton = () => {
        return (selectedElement === "");
    }

    function addToSelectedElements(e) {
        e.preventDefault();
        props.setSelectedElements([...props.selectedElements, selectedElement]);
        deleteFromElements(selectedElement);
    }

    function addToElements(element) {
        setElements([...elements, element]);
    }

    const deleteFromSelectedElements = (e) => {
        e.preventDefault();

        let elementToDelete = e.target.closest(".tag").getAttribute("value");
        elementToDelete = JSON.parse(elementToDelete);
        addToElements(elementToDelete);

        elementToDelete = props.selectedElements.find(elementAux => elementAux.id === elementToDelete.id);
        let index = props.selectedElements.indexOf(elementToDelete);

        props.selectedElements.splice(index, 1)
        props.setSelectedElements([...props.selectedElements]);
    }

    const deleteFromElements = (element) => {
        let elementToDelete = elements.find(elementAux => elementAux.id === element.id);
        if (elementToDelete !== undefined) {
            let index = elements.indexOf(elementToDelete);
            elements.splice(index, 1);
            setElements([...elements])
        }
    }

    const showElement = element => {
        let elementString = "";
        props.exposedAttribute.split(",").map(attribute => elementString += `${element[attribute]} `)
        return elementString;
    }

    function showSelect() {
        if (props.show) {
            return (
                <fieldset className={"block"} disabled={props.disable}>
                    <label className="label">{intl.formatMessage({id: props.label})}</label>
                    <div className="field has-addons">
                        <div className="control is-expanded">
                            <div className="select is-fullwidth">
                                <select
                                    name={props.name}
                                    onChange={(e) => {
                                        setSelectedElement(JSON.parse(e.target.value))
                                    }}>
                                    <option>
                                        {intl.formatMessage({id: props.placeholder})}
                                    </option>
                                    {elements.map(element =>
                                        <option key={element.id} value={JSON.stringify(element)}>
                                            {showElement(element)}
                                        </option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="control">
                            <button className="button is-primary"
                                    onClick={(e) => addToSelectedElements(e)}
                                    disabled={disableAddButton()}>
                                {intl.formatMessage({id: "general.add"})}
                            </button>
                        </div>
                    </div>
                </fieldset>
            )
        }
    }

    function showTitle() {
        if (props.show) {
            return (<p className="mt-5">{intl.formatMessage({id: props.subtitle})}</p>)
        } else {
            return (<div>
                {userType === userTypes.CLIENT_ADMIN ?
                    (
                        <div className={"is-flex is-justify-content-flex-end"}>
                            <button className="button is-outlined is-primary" onClick={props.handleClick}>{
                                props.from === "user"?
                                    intl.formatMessage({id: "department.add_department"}) :
                                    intl.formatMessage({id: "department.add_user"})
                            }</button>
                        </div>
                    ):
                    (<></>)
                }
                <label className="label">{intl.formatMessage({id: props.subtitle})}</label>
            </div>)
        }
    }

    return (
        <div className="is-desktop box has-background-light">

            {showSelect()}
            {showTitle()}

            <div className="mt-2">
                {props.selectedElements?.map(element =>
                    <div className="tag is-link is-medium mr-1 mt-1" key={element.id} value={JSON.stringify(element)}>
                        {showElement(element)}
                        {props.show && (
                            <button className="delete is-small" onClick={deleteFromSelectedElements}/>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
