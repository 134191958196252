// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// ** Icons Imports
import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ** Store Imports
import { login } from "../../store/reducers/auth/authActions";

// ** Actions Import
import * as actionTypes from "../../store/Actions";

let Login = () => {
    // ** States
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // ** Hooks
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const isLoggedIn = useSelector(state => state.auth.isAuthenticated);
    const badCredentials = useSelector(state => state.auth.badCredentials);
    const error = useSelector(state => state.auth.error);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch({type: actionTypes.HIDE_MODAL});
            toast.success(intl.formatMessage({id: "message.welcome"}));
            history.push("/");
        } else if (error !== null) {
            dispatch({type: actionTypes.HIDE_MODAL});
            switch (error) {
                case "Unauthorized":
                    showAlert();
                    dispatch({type: "RESET_AUTH_ERROR"})
                    break;
                default:
            }
        } else dispatch({type: actionTypes.HIDE_MODAL});
    });

    useEffect(() => {
        if (badCredentials) {
            dispatch({type: actionTypes.HIDE_MODAL});
            toast.error(intl.formatMessage({id: "error.bad_credentials"}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [badCredentials])

    const onSubmit = event => {
        event.preventDefault();
        dispatch({type: actionTypes.RESET_BAD_CREDENTIALS});
        dispatch({type: 'TOGGLE_MODAL', payload: {modalType: "loading"}})
        dispatch(login({email: email, password: password}));
    }

    const showAlert = () => {
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                title: intl.formatMessage({id: "error.wrong_credentials_title"}),
                modalType: "alert",
                message: intl.formatMessage({id: "error.wrong_credentials"}),
                acceptButton: intl.formatMessage({id: "general.accept"}),
                acceptButtonClass: "is-danger"
            }
        })
    }

    return (
        <section
            className="hero is-fullheight"
            style={{backgroundImage: `url("${require("../../assets/idisc-xpress.jpg")}`}}
        >
            <div className="hero-body">
                <div className="container" style={{opacity: 1.2}}>
                    <div className="column is-4 is-offset-4">
                        <div className="box">
                            <h3 className="title has-text-primary has-text-centered">
                                <FormattedMessage id="login"/>
                            </h3>
                            <form onSubmit={onSubmit}>
                                <div className="field">
                                    <div className="control has-icons-left">
                                        <input
                                            autoFocus
                                            className="input"
                                            id="email"
                                            onChange={event => setEmail(event.target.value)}
                                            placeholder={intl.formatMessage({id: "placeholder.email"})}
                                            type="email"
                                            value={email}
                                        />
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon
                                                className="mr-2 has-text-primary is-hidden-mobile"
                                                color="primary"
                                                icon={faUser}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="field mt-4">
                                    <div className="control has-icons-left">
                                        <input
                                            className="input"
                                            id="password"
                                            onChange={event => setPassword(event.target.value)}
                                            placeholder={intl.formatMessage({id: "placeholder.password"})}
                                            type="password"
                                            value={password}
                                        />
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon
                                                className="mr-2 has-text-primary is-hidden-mobile"
                                                icon={faKey}
                                                color="primary"
                                            />
                                        </span>
                                    </div>
                                    {badCredentials && (
                                        <p className="help is-danger">
                                            <FormattedMessage id="error.bad_credentials"/>
                                        </p>
                                    )}
                                </div>
                                <button
                                    className="button is-block is-fullwidth is-link"
                                    disabled={email.trim() === "" || password.trim() === ""}
                                    id="login_submit"
                                    type="submit"
                                >
                                    <FormattedMessage id="login"/>
                                    <i className="fa fa-sign-in" aria-hidden="true"/>
                                </button>
                                <hr/>
                                <div className="columns">
                                    <div className="column has-text-centered">
                                        <Link className="has-text-primary" to="/login/reset">
                                            <FormattedMessage id="user.forgot_password"/>
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => ({isAuthenticated: state.auth.isAuthenticated, token: state.auth.token});

const mapDispatchToProps = dispatch => ({onLogin: token => dispatch({type: actionTypes.LOGIN, token: token})});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
