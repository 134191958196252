// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";

// ** Store Imports
import { getPrices, getTask } from "../../store/reducers/task/taskActions";

// ** Custom Components
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Datatable from "../../components/Datatable/Datatable";
import OnlyReadInput from "../../components/Form/OnlyReadInput";

// ** Hooks
import { usePrice } from "../../hooks/usePrice";

const ShowTaskQuote = props => {
    const id = props.match.params.id;

    // ** States
    const [files, setFiles] = useState([]);
    const [prices, setPrices] = useState([]);

    // ** Hooks
    const history = useHistory();
    const intl = useIntl();
    const dispatch = useDispatch();
    const task = useSelector(state => state.task.task);
    const pricesSummary = useSelector(state => state.task.pricesSummary);
    const locale = useSelector(state => state.locale.locale);
    const price = usePrice();

    useEffect(() => {
        dispatch(getTask(id));
        dispatch(getPrices(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (task) {
            const files = [];
            task.source_files.forEach(file => file.word_count.forEach(wordCount => files.push({
                name: file.file_name,
                source_language: wordCount.source_language_o_b_j.iso_code,
                target_language: wordCount.target_language_o_b_j.iso_code,
                total_words: wordCount.total_words,
                no_match: wordCount.percent95 + wordCount.percent85 + wordCount.percent75 + wordCount.percent50 + wordCount.no_match,
                repetitions: wordCount.repetitions,
                '100_match': wordCount.percent101 + wordCount.percent100
            })));
            setFiles(files);
        }
    }, [task]);

    useEffect(() => {
        if (pricesSummary) {
            const prices = [];
            pricesSummary.forEach(price => prices.push({
                "target_language_id": price.targetLanguageId,
                "total": price.total,
                "is_price_by_hour": price.isPriceByHour,
                "total_words": [
                    {
                        "detail": intl.formatMessage({id: "task.new"}),
                        "word_count": price.noMatch.wordCount,
                        "price_per_word": price.noMatch.pricePerWord,
                        "total": price.noMatch.total
                    },
                    {
                        "detail": intl.formatMessage({id: "task.repetitions"}),
                        "word_count": price.repetitions.wordCount,
                        "price_per_word": price.repetitions.pricePerWord,
                        "total": price.repetitions.total
                    },
                    {
                        "detail": intl.formatMessage({id: "task.in_memory"}),
                        "word_count": price["100Match"].wordCount,
                        "price_per_word": price["100Match"].pricePerWord,
                        "total": price["100Match"].total
                    }
                ]
            }));
            setPrices(prices);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricesSummary]);

    const columns = [
        {
            name: intl.formatMessage({id: "task.fileName"}),
            selector: "name",
            sortable: true,
            wrap: true
        },
        {
            name: intl.formatMessage({id: "task.source_language"}),
            selector: row => intl.formatMessage({id: `language.${row.source_language}`}),
            sortable: true
        },
        {
            name: intl.formatMessage({id: "task.target_language"}),
            selector: row => intl.formatMessage({id: `language.${row.target_language}`}),
            sortable: true
        },
        {
            name: intl.formatMessage({id: "task.total_words"}),
            selector: "total_words",
            sortable: true,
            right: true
        },
        {
            name: intl.formatMessage({id: "task.no_match"}),
            selector: "no_match",
            sortable: true,
            right: true
        },
        {
            name: intl.formatMessage({id: "task.repetitions"}),
            selector: "repetitions",
            sortable: true,
            right: true
        },
        {
            name: intl.formatMessage({id: "task.100_match"}),
            selector: "100_match",
            sortable: true,
            right: true
        }
    ];

    const columns2 = [
        {
            name: intl.formatMessage({id: "task.detail"}),
            selector: "detail",
            sortable: true,
            grow: 2
        },
        {
            name: intl.formatMessage({id: "task.wordCount"}),
            selector: "word_count",
            sortable: true,
            right: true
        },
        {
            name: intl.formatMessage({id: "task.cost_per_word"}),
            selector: row => `${price(row.price_per_word, locale.name, 4)} €`,
            sortable: true,
            right: true
        },
        {
            name: intl.formatMessage({id: "task.total"}),
            selector: row => `${price(row.total, locale.name)} €`,
            sortable: true,
            right: true
        }
    ];

    return (
        <div className="box is-desktop">
            <div className="columns is-mobile">
                <Breadcrumbs
                    title={intl.formatMessage({id: "task.wordCount"})}
                    destination={["/tasks", `/tasks/${id}`]}
                    labels={[
                        intl.formatMessage({id: "menu.tasks"}),
                        task?.description ?? intl.formatMessage({id: "task.project"})
                    ]}
                />
            </div>
            <hr/>
            <div className="columns is-desktop">
                <div className="column is-2">
                    <OnlyReadInput label="task.id" fieldValue={task?.id ?? ""}/>
                </div>
                <div className="column is-5">
                    <OnlyReadInput label="task.name" fieldValue={task?.description ?? ""}/>
                </div>
                <div className="column is-5">
                    <OnlyReadInput
                        label="general.company"
                        fieldValue={task?.user?.customer.name ?? intl.formatMessage({id: "general.guest"})}
                    />
                </div>
            </div>
            <hr/>
            <h1 className="title is-5 has-text-primary">
                <FormattedMessage id="task.wordCount"/>
            </h1>
            <Datatable columns={columns} data={files} highlightOnHover={false} pointerOnHover={false}/>
            <hr/>
            <h1 className="title is-5 has-text-primary">
                <FormattedMessage id="task.breakdown_languages_summary"/>
            </h1>
            {task?.target_languages?.map(language => {
                const p = prices.find(price => price.target_language_id === language.target_language_id);

                return (
                    <div key={language.target_language_id}>
                        <div className="columns">
                            <div className="column is-8">
                                <FormattedMessage id={`language.${task.source_language.iso_code}`}/>{">"}
                                <FormattedMessage id={`language.${language.target_language.iso_code}`}/>{" "}
                                ({task.source_language.iso_code}>{language.target_language.iso_code})
                            </div>
                            <div className="column">
                                <div className="has-text-weight-bold is-flex is-justify-content-flex-end">
                                    {p && `${price(p.total, locale.name)} €`}
                                </div>
                            </div>
                        </div>
                        {p && p.is_price_by_hour && (
                            <div className="is-flex is-justify-content-flex-end">
                                <FormattedMessage id="task.cost_price_by_hour"/>
                            </div>
                        )}
                        <div className="columns">
                            <div className="column">
                                <Datatable
                                    columns={columns2}
                                    data={p?.total_words ?? []}
                                    highlightOnHover={false}
                                    pointerOnHover={false}
                                />
                            </div>
                        </div>
                    </div>
                )
            })}
            <div className="columns">
                <div className="column is-offset-5">
                    <div className="custom-border-black-3 columns has-background-danger-light">
                        <div className="column has-text-right has-text-weight-bold">
                            <FormattedMessage id="task.total_translation"/>
                        </div>
                        <div className="column has-text-right">
                            {`${price(task?.base_price ?? 0, locale.name)} €`}
                        </div>
                    </div>
                </div>
            </div>
            <button className="button" onClick={() => history.push(`/tasks/${id}`)}>
                {intl.formatMessage({id: "general.back"})}
            </button>
        </div>
    );
};

export default connect()(ShowTaskQuote);
